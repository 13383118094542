<div class="container-fluid bg-light-30p h-100 pb-3 full-height">
  <div class="row pt-3" *ngIf="loading">
    <div class="col text-center">
      <i class="fa fa-spinner spinning fa-2x"></i>
    </div>
  </div>
  <ng-container *ngIf="!loading">
    <div class="row pt-3" *ngIf="data.length == 0">
      <div class="col text-center">
        {{ 'global.Aucun résultat trouvé' | translate }}
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6" *ngFor="let kpi of data">
        <hiji-table-kpi [objective]="kpi"></hiji-table-kpi>
      </div>
    </div>
  </ng-container>
</div>
